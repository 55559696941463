<template>
  <transition-group name="tag" tag="ul" class="radio-wrap">
    <li v-for="category in categories" :key="category.id">
      <label>
        <input
          type="radio"
          name="ct"
          :value="category.id"
          :checked="value === category.id"
          @change="onSelectCategory"
        />
        <span>
          {{ category.name }}
        </span>
      </label>
    </li>
  </transition-group>
</template>

<script>
export default {
  name: "Categories",
  props: {
    categories: Array,
    value: Number,
  },
  methods: {
    onSelectCategory(ev) {
      this.$emit("select", parseInt(ev.target.value));
    },
  },
};
</script>

<style lang="scss">
@import "~@/color";

.radio-wrap {
  display: flex;
  flex-wrap: wrap;
  padding: 0rem 1rem;

  li {
    padding: 0.4rem;

    input {
      display: none;
    }

    input[type="radio"]:checked {
      & + span {
        background-color: $violet01;
        color: white;
      }
    }

    span {
      cursor: pointer;
      display: block;
      font-size: 1.2rem;
      background-color: $gray03;
      border-radius: 3px;
      color: $gray07;
      padding: 0.5rem 1.4rem;
    }
  }
}

.tag-enter-active,
.tag-leave-active {
  transition: all 1s;
}

.tag-enter-from,
.tag-leave-to {
  opacity: 0;
}
</style>
