<template>
  <div class="container">
    <div class="title">
      <h1>문의{{ isEditMode ? "수정" : "작성" }}</h1>
      <span>* 필수값을 채워주세요.</span>
    </div>

    <div class="write-content">
      <div>
        <span class="sub-title">* 카테고리</span>
        <div>
          <st-categories
            :value="category"
            :categories="categories"
            @select="onSelectCategory"
          />
        </div>
      </div>

      <div>
        <span class="sub-title">* 제목</span>
        <input type="text" v-model="title" placeholder="제목을 입력해주세요." />
      </div>

      <div class="txt-area">
        <textarea placeholder="문의 내용을 입력해주세요." v-model="content" />
      </div>

      <div>
        <span class="sub-title">첨부파일</span>
        <div>
          <div class="preview-wrap">
            <div class="preview-cover" :style="previewStyle">
              <st-preview
                v-for="(file, index) in previewItems"
                :key="file.name"
                :file="file"
                :index="index"
                @delete="onFileDelete"
              />

              <label class="upload-label">
                <input
                  type="file"
                  accept=".jpg, .png, .jpeg, .gif"
                  multiple
                  @change="onFileUpload"
                />
                <div class="upload-button-target">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="paperclip"
                    class="svg-inline--fa fa-paperclip fa-w-14"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="currentColor"
                      d="M43.246 466.142c-58.43-60.289-57.341-157.511 1.386-217.581L254.392 34c44.316-45.332 116.351-45.336 160.671 0 43.89 44.894 43.943 117.329 0 162.276L232.214 383.128c-29.855 30.537-78.633 30.111-107.982-.998-28.275-29.97-27.368-77.473 1.452-106.953l143.743-146.835c6.182-6.314 16.312-6.422 22.626-.241l22.861 22.379c6.315 6.182 6.422 16.312.241 22.626L171.427 319.927c-4.932 5.045-5.236 13.428-.648 18.292 4.372 4.634 11.245 4.711 15.688.165l182.849-186.851c19.613-20.062 19.613-52.725-.011-72.798-19.189-19.627-49.957-19.637-69.154 0L90.39 293.295c-34.763 35.56-35.299 93.12-1.191 128.313 34.01 35.093 88.985 35.137 123.058.286l172.06-175.999c6.177-6.319 16.307-6.433 22.626-.256l22.877 22.364c6.319 6.177 6.434 16.307.256 22.626l-172.06 175.998c-59.576 60.938-155.943 60.216-214.77-.485z"
                    ></path>
                  </svg>
                  <span>파일 업로드</span>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="write-button-area">
      <button class="back" @click="goBack">
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="arrow-left"
          class="svg-inline--fa fa-arrow-left fa-w-14"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
        >
          <path
            fill="currentColor"
            d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"
          ></path>
        </svg>
      </button>
      <button class="with-back" @click="onQnaCreate" :disabled="loading">
        {{
          loading
            ? "잠시만 기다려주세요."
            : isEditMode
            ? "수정하기"
            : "제출하기"
        }}
      </button>
    </div>
  </div>
</template>

<script>
import Categories from "../components/Categories";
import Preview from "../components/Preview";

import sanitizeHtml from "sanitize-html";
import xssEscape from "xss-escape";

export default {
  name: "Write",
  data: () => ({
    id: null,
    title: null,
    content: null,
    category: -1,
    // ready
    files: [],
    attached: [], // edit 용
    loading: false,
  }),
  components: {
    "st-categories": Categories,
    "st-preview": Preview,
  },
  computed: {
    categories() {
      return this.$store.state.categories;
    },

    user() {
      return this.$store.state.user;
    },

    previewItems() {
      return [...this.files, ...this.attached];
    },

    previewStyle() {
      const width = this.previewItems.length * (140 + 10) + 32 + 100;
      return `width: ${width}px`;
    },

    isEditMode() {
      const { id } = this.$route.params;
      return !!id;
    },
  },
  methods: {
    goBack() {
      const self = this;

      if (!this.title && !this.content) {
        self.$router.go(-1);
        return;
      }

      this.$confirm({
        title: "작성중인 문의",
        content: "작성중인 문의가 있습니다. 나가시겠습니까?",
        onConfirm() {
          self.$router.go(-1);
        },
      });
    },

    onFileDelete(index) {
      const item = this.previewItems[index];

      if (item instanceof Blob) {
        const index = this.files.findIndex(({ name }) => name === item.name);
        this.files.splice(index, 1);
      } else {
        const index = this.attached.findIndex(({ id }) => id === item.id);
        this.attached.splice(index, 1);
      }
    },

    /**
     * @function onFileUpload
     * @parameter
     *  - value
     * @description
     *  파일 선택 input<change>
     */
    onFileUpload(ev) {
      const files = Array.from(ev.target.files);
      let error = false;

      if (this.files.length + files.length > 5) {
        this.$alert.error("이미지는 5개까지 첨부가능합니다.");
        ev.target.value = "";
        return;
      }

      for (let i = 0; i < files.length; i++) {
        const { size, type } = files[i];

        if (!/(image)/.test(type)) {
          this.$alert.error("이미지 파일만 첨부가능합니다.");
          error = true;
          break;
        }

        if (5242880 < size) {
          this.$alert.error("파일 크기는 5MB 이하여야합니다.");
          error = true;
          break;
        }
      }

      if (!error) {
        this.files = [...this.files, ...files];
      }

      ev.target.value = "";
    },

    /**
     * @function onRequestFileId
     * @parameter
     *  - value
     * @description
     *  파일 업로드 후 id 받기
     */
    async onRequestFileId() {
      this.loading = true;

      try {
        const result = await Promise.all(
          this.files.map(async (file) => {
            const fd = new FormData();
            fd.append("upload", file);
            fd.append("result_type", "insert_id");

            const { data } = await this.$api.upload(fd);

            return data;
          })
        );

        return result;
      } catch (err) {
        console.log(err);
      }
    },

    /**
     * @function onSelectCategory
     * @parameter
     *  - value
     * @description
     *  카테고리 선택
     */
    onSelectCategory(value) {
      this.category = value;
    },

    /**
     * @function onQnaCreate
     */
    onQnaCreate() {
      if (!this.user) {
        this.$alert.error("사용자가 없습니다.");
        return;
      }

      const { user_id, phone } = this.user;
      const category_id = this.category;

      if (category_id === -1) {
        this.$alert.error("카테고리를 선택해주세요.");
        return;
      }

      if (!this.title) {
        this.$alert.error("제목을 작성해주세요.");
        return;
      }

      if (!this.content) {
        this.$alert.error("내용을 작성해주세요.");
        return;
      }

      if (!phone || !user_id) {
        this.$alert.error("사용자 정보가 없습니다.");
        return;
      }

      this.$confirm({
        title: "문의제출",
        content: "작성하신 문의를 제출하시겠습니까?",
        onConfirm: async () => {
          try {
            let attachs_ids = await this.onRequestFileId();

            const pretty = sanitizeHtml(this.content, {
              allowedTags: [
                "div",
                "img",
                "label",
                "button",
                "p",
                "h1",
                "h2",
                "h3",
                "h4",
                "h5",
                "h6",
                "br",
                "hr",
              ],
              selfClosing: ["br", "a", "hr"],
            });

            const escapeContent = xssEscape(pretty);

            if (this.attached.length > 0) {
              const attached = this.attached.map(({ id }) => id);
              attachs_ids = [...attachs_ids, ...attached];
            }

            const body = {
              title: this.title,
              content: escapeContent,
              category_id: this.category,
              attach_ids: JSON.stringify(attachs_ids),
              phone,
              user_id,
            };

            if (this.isEditMode) {
              await this.$api.putUpdate(this.$route.params.id, body);
            } else {
              await this.$api.postWrite(body);
            }

            this.clear();
            this.loading = false;

            this.$alert.success(
              this.isEditMode
                ? "수정을 성공적으로 완료했습니다."
                : "문의를 성공적으로 등록했습니다."
            );
            this.$store.dispatch("CLEAR_AND_GET_LIST");
            this.$router.push("/list");
          } catch (err) {
            this.loading = false;
            this.$alert.error("문의 등록에 실패 하였습니다.");
          }
        },
      });
    },

    async getUpdateData(id) {
      try {
        let response = null;

        if (this.$store.state.view_data) {
          response = { data: this.$store.state.view_data };
        } else {
          response = await this.$api.getView(id);
        }

        if (response.data) {
          const { title, content, category_id, attachs } = response.data;

          this.title = title;
          this.content = content;
          this.category = category_id;
          this.attached = attachs;
        }
      } catch (err) {
        console.log(err);
      }
    },

    clear() {
      Object.assign(this.$data, this.$options.data());
    },
  },
  mounted() {
    const { id } = this.$route.params;

    if (id) {
      this.getUpdateData(id);
    }
  },
  beforeUnmount() {
    this.clear();
    this.$store.commit("SET_EDIT_VIEW_DATA", null);
  },
};
</script>

<style lang="scss">
@import "~@/color";

.write-content {
  padding: 6px 0 100px;
}

.sub-title {
  font-size: 13px;
  display: block;
  padding: 12px 16px;
  color: $violet01;
  font-weight: 500;
}

input[type="text"] {
  font-size: 14px;
  height: 48px;
  border: none;
  outline: none;
  padding: 0 16px;
  width: 100%;
}

.txt-area {
  margin-top: 16px;
}

textarea {
  min-height: 300px;
  max-height: 500px;
  width: 100%;
  box-shadow: none;
  border: none;
  border-radius: 0;
  outline: none;
  font-size: 14px;
  padding: 18px 16px;
  resize: none;
}

.upload-label {
  input[type="file"] {
    display: none;
  }
}

.upload-button-target {
  position: relative;
  width: 100px;
  height: 100px;
  overflow: hidden;
  display: inline-block;
  border-radius: 16px;
  background-color: $gray02;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  svg {
    color: $gray05;
    width: 30%;
    height: 30%;
  }

  span {
    color: $gray07;
    margin-top: 6px;
    font-size: 11px;
    font-weight: 500;
  }
}

.preview-wrap {
  width: 100%;
  overflow-x: auto;
}

.preview-cover {
  display: flex;
  padding: 0 16px;
}
</style>
