<template>
  <div class="preview-item">
    <button class="preview-delete" @click="$emit('delete', index)">
      &times;
    </button>
    <img :src="url" :title="name" />
  </div>
</template>

<script>
export default {
  name: "Preview",
  data: () => ({
    name: null,
    url: null,
  }),
  props: {
    file: Object,
    index: Number,
  },
  mounted() {
    const { name, url } = this.file;

    if (url) {
      this.url = url;
      return;
    }

    const self = this;
    this.name = name;

    const reader = new FileReader();
    reader.onload = function (ev) {
      self.url = ev.target.result;
    };

    reader.readAsDataURL(this.file);
  },
};
</script>

<style lang="scss">
@import "~@/color";

.preview-item {
  position: relative;
  width: 140px;
  height: 100px;
  overflow: hidden;
  display: inline-block;
  border-radius: 16px;
  border: 1px solid #e0e0e0;

  .preview-delete {
    position: absolute;
    right: 12px;
    top: 8px;
    border: none;
    width: 20px;
    height: 20px;
    font-size: 14px;
    font-weight: 600;
    background-color: white;
    line-height: 20px;
    border-radius: 50%;
    cursor: pointer;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.preview-item + * {
  margin-left: 10px;
}
</style>
